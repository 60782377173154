import React from 'react';

const Logo = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 269.62 192.73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="fill-black dark:fill-white" d="M237.51,192.73L183.84,45.78L130.1,192.73H96.67L36.13,97.03h27.39c5.08,0,13.67-2.24,21.12-8.57
        c7.78-6.6,11.73-15.45,11.73-26.29c0-16.94-11.54-25.09-16.5-27.82c-5-2.74-10.81-4.19-16.81-4.19H0V0h63.06
        c11.05,0,21.88,2.74,31.32,7.91c5.94,3.26,14.48,9.24,21.41,19.23c7.13,10.26,10.74,22.05,10.74,35.03
        c0,10.36-2.16,20.06-6.41,28.82c-3.8,7.82-9.17,14.71-15.96,20.47c-5.01,4.25-10.69,7.76-16.63,10.38l23.05,36.44L168.46,0l30.77,0
        l70.39,192.72L237.51,192.73z"/>
    </svg>
  );
};

export default Logo;
