import React from "react";
import { withRouter } from 'react-router-dom';
import Logo from "../logo/logo"
import DarkMode from "../dark-mode/dark-mode"

let socialLinks = [
  { title: "Email", href: "mailto:rafael@rafaelanderka.com" },
  { title: "GitHub", href: "https://github.com/rafaelanderka" },
]

let education = [
  { name: "MSc Machine Learning", uni: "University College London", period: "2022 - 2023", href: "https://www.ucl.ac.uk/ai-centre/study" },
  { name: "BSc (Hons) Computer Science & Mathematics", uni: "University of Edinburgh", period: "2018 - 2022", href: "https://www.ed.ac.uk/studying/undergraduate/degrees/index.php?action=view&code=GG14" },
]

export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Home | Rafael Anderka"
    this.props.setCursorCol("#999");
  }

  render() {
    return (
      <div className="home relative z-10 flex items-stretch">
        <div className="relative z-20 p-4 sm:p-16">
          <section className="max-w-[33rem]">
            <header className="flex justify-between items-center mb-20 sm:mb-32">
              <a href="."><Logo /></a>
              <button onClick={this.props.toggleDarkMode}><DarkMode /></button>
            </header>
            <div className="mb-12">
              <h1 className="mb-1">Rafael Anderka</h1>
              <ul className="flex space-x-2 text-xs opacity-75">
                {socialLinks.map((link) => (
                  <li key={link.href}>
                    <ExternalLink href={link.href} title={link.title} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-12">
              <p className="mb-4">Postgraduate student in Machine Learning at {" "}<ExternalLink title="University College London" href="https://www.ucl.ac.uk/ai-centre/study" />.</p>
              <p>
                Currently in the{" "}
                <ExternalLink title="Statistical Machine Learning Group" href="https://www.sml-group.cc/" />,
                working on Bayesian inference with SPDE priors.
              </p>
            </div>
            <Section title="Education" underlineLinks={false}>
              <ul>
                {education.map((edu) => (
                  <li className="relative flex" key={edu.name}>
                    <span className="mr-4 inline-block w-28 sm:w-36 flex-shrink-0">{edu.period}</span>
                    <a href={edu.href} target="_blank" rel="noopener noreferrer">
                      <span className="inline-block w-70">{edu.name}</span>
                      <br />
                      <span className="inline-block w-70">{edu.uni}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </Section>
            <Section title="Recent Projects" underlineLinks={true}>
              {" "}
              <ExternalLink title="cNeRF" href="https://github.com/rafaelanderka/cNeRF" />:
              A concise C++ implementation of Neural Radiance Fields.
              <br />
              {" "}
              <ExternalLink title="Lattice-Boltzmann simulator" href="https://github.com/rafaelanderka/lattice-boltzmann-simulator" />:
              Advection-Diffusion equation in WebGL.
            </Section>
            {/* <div className="mb-6"> */}
            {/*   <h2 className="mb-3 text-green">Modelling & Simulation Experience</h2> */}
            {/*   <ul> */}
            {/*     {education.map((role) => ( */}
            {/*       <li className="relative flex" key={role.name}> */}
            {/*         <span className="mr-4 inline-block w-36">{role.period}</span> */}
            {/*         <a href={role.href} target="_blank" rel="noopener noreferrer"> */}
            {/*           <span className="inline-block w-60">{role.name}</span> */}
            {/*           <span className="relative inline-block -rotate-45 select-none"> */}
            {/*             <Icon name="arrow-right" width={14} height={14} /> */}
            {/*           </span> */}
            {/*         </a> */}
            {/*       </li> */}
            {/*     ))} */}
            {/*   </ul> */}
            {/* </div> */}
            {/* <Section title="Recent writing"> */}
            {/*   {allBlogPosts */}
            {/*     .sort((a, b) => b.date.localeCompare(a.date)) */}
            {/*     .map((post, i, arr) => ( */}
            {/*       <Fragment key={post.slug}> */}
            {/*         <Link href={post.url}>{post.title}</Link> */}
            {/*         <span className="pl-2 opacity-50">{post.year}</span> */}
            {/*         {i !== arr.length - 1 && <br />} */}
            {/*       </Fragment> */}
            {/*     ))} */}
            {/* </Section> */}
            <footer className="text-xs opacity-75">
              <p>
                Last updated 04/2023.
              </p>
            </footer>
          </section>
        </div>
      </div>
    );
  }
}

function Section(props) {
  const { title, children, underlineLinks } = props
  return (
    <div className="mb-6">
      <h2 className="mb-3 text-sky-800 dark:text-sky-100/90">{title}</h2>
      {underlineLinks ? <p>{children}</p> : children}
    </div>
  )
}

function ExternalLink(props) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.title}
    </a>
  )
}
