import React from 'react';
import ReactDOM from 'react-dom';
import requestAnimFrame from '../../helpers/request-anim-frame';

export default class CursorPosition extends React.Component {
  constructor(props) {
    super(props);

    // Constants
    this.springConst = 1.5;
    this.viscousCoeff = 15;
    this.omega = Math.sqrt(this.springConst);
    this.zeta = this.viscousCoeff / (2 * Math.sqrt(this.springConst));

    // Internal state for synchronous updates
    this.container = null;
    this.cursorPos = { x: this.props.vw * 0.5, y: this.props.vh * 0.45 };
    this.relCursorPos = { x: 0.5, y: 0.45 };
    this.lastRelCursorPos = { x: 0, y: 0 };
    this.relCursorVel = { x: 0, y: 0 };
    this.springPos = { x: this.props.vw * 0.5, y: this.props.vh };
    this.springVel = { x: 0, y: 0 };
    this.isCursorActive = false;

    // React state for asynchronous updates
    this.state = {
      isInitialised: false,
      cursorPos: { x: 0, y: 0 },
      relCursorPos: { x: 0, y: 0 },
      relCursorVel: { x: 0, y: 0 },
      springPos: { x: 0, y: 0 },
      springVel: { x: 0, y: 0 },
      isCursorActive: false,
    };
  }

  // Helper function to get an element's exact position
  _getPosition(el) {
    let xPos = 0;
    let yPos = 0;

    while (el) {
      if (el.tagName == "BODY") {
        // Deal with browser quirks with body/window/document and page scroll
        let xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        let yScroll = el.scrollTop || document.documentElement.scrollTop;
        xPos += (el.offsetLeft - xScroll + el.clientLeft);
        yPos += (el.offsetTop - yScroll + el.clientTop);
      } else {
        // For all other non-BODY elements  
        xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
        yPos += (el.offsetTop - el.scrollTop + el.clientTop);
      }
      el = el.offsetParent;
    }
    return {
      x: xPos,
      y: yPos
    };
  }

  _setCursorState(e) {
    // Calculate updated cursor state
    this.lastRelCursorPos = this.relCursorPos;
    this.cursorPos = {
      x: e.clientX,
      y: e.clientY
    };
    this.relCursorPos = {
      x: this.cursorPos.x / this.props.vw,
      y: this.cursorPos.y / this.props.vh
    };
    this.relCursorVel = {
      x: this.relCursorPos.x - this.lastRelCursorPos.x,
      y: this.relCursorPos.y - this.lastRelCursorPos.y
    };

    // Update cursor state
    this.setState({
      cursorPos: this.cursorPos,
      relCursorPos: this.relCursorPos,
      relCursorVel: this.relCursorVel
    });
  }
  _springUpdate() {
    // Callback
    requestAnimFrame(() => this._springUpdate());

    // Calculate updated spring state
    const springAcc = {
      x: (((Math.pow(this.omega, 2) * (this.cursorPos.x - this.springPos.x)) - (2 * this.omega * this.zeta * this.springVel.x)) * 1 / 100),
      y: (((Math.pow(this.omega, 2) * (this.cursorPos.y - this.springPos.y)) - (2 * this.omega * this.zeta * this.springVel.y)) * 1 / 100)
    }

    this.springVel = {
      x: this.springVel.x + springAcc.x,
      y: this.springVel.y + springAcc.y
    }

    this.springPos = {
      x: this.springPos.x + this.springVel.x,
      y: this.springPos.y + this.springVel.y
    }

    // Update spring state
    this.setState({
      springPos: this.springPos,
      springVel: this.springVel
    });

    this.props.setCursorState({
      vw: this.props.vw,
      vh: this.props.vh,
      pixelRatio: window.devicePixelRatio || 1.0,
      cursorPos: this.state.cursorPos,
      relCursorPos: this.state.relCursorPos,
      relCursorVel: this.state.relCursorVel,
      springPos: this.state.springPos,
      springVel: this.state.springVel,
      isCursorActive: this.state.isCursorActive,
    });
  }

  componentDidMount() {
    // Get the container
    this.container = ReactDOM.findDOMNode(this).parentNode;

    // Handle mouse input
    this.container.addEventListener("mousemove", e => this._setCursorState(e), false);
    this.container.addEventListener("mousedown", () => {
      this.isCursorActive = true;
      this.setState({ isCursorActive: this.isCursorActive });
      this.props.setIsCursorActive(true);
    });
    this.container.addEventListener("mouseup", () => {
      this.isCursorActive = false;
      this.setState({ isCursorActive: this.isCursorActive });
      this.props.setIsCursorActive(false);
    });

    // Handle touch input
    this.container.addEventListener("touchstart", e => {
      this._setCursorState(e.targetTouches[0]);
      this.isCursorActive = true;
      this.setState({ isCursorActive: this.isCursorActive });
      this.props.setIsCursorActive(true);
    });
    this.container.addEventListener("touchend", () => {
      this.isCursorActive = false;
      this.setState({ isCursorActive: this.isCursorActive });
      this.props.setIsCursorActive(false);
    });
    this.container.addEventListener("touchmove", e => {
      //e.preventDefault();
      this._setCursorState(e.targetTouches[0]);
    }, false);

    // Start spring animation
    requestAnimFrame(() => this._springUpdate());

    // Initialisation complete
    this.setState({ isInitialised: true });
  }

  render() {
    if (this.state.isInitialised) {
      // Add props to children
      const children = React.Children.map(this.props.children, child => {
        return React.cloneElement(child, {
          vw: this.props.vw,
          vh: this.props.vh,
          pixelRatio: window.devicePixelRatio || 1.0,
          cursorPos: this.state.cursorPos,
          relCursorPos: this.state.relCursorPos,
          relCursorVel: this.state.relCursorVel,
          springPos: this.state.springPos,
          springVel: this.state.springVel,
          isCursorActive: this.state.isCursorActive,
        });
      });

      // Render children with added props
      return children != undefined ? children : <div />;
    }

    return <div />;
  }
}
