import React from "react";
import { Route } from "react-router-dom";
import CursorPosition from "../cursor-position/cursor-position";
import Cursor from "../cursor/cursor";
import Home from "../home/home";
import colors from 'tailwindcss/colors'

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      isCursorPositionInitialised: false,
      viewportWidth: 0,
      viewportHeight: 0,
      aspect: 1,
      cursorState: null,
      cursorCol: "#999",
      isCursorActive: false,
      useDarkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    };

    this.updateViewportDimensions = this.updateViewportDimensions.bind(this);
    this.setCursorState = this.setCursorState.bind(this);
    this.setIsCursorActive = this.setIsCursorActive.bind(this);
    this.setCursorCol = this.setCursorCol.bind(this);
    this.setDarkMode = this.setDarkMode.bind(this);
  }

  componentDidMount() {
    this.setDarkMode(this.state.useDarkMode);
    this.updateViewportDimensions();
    window.addEventListener('resize', this.updateViewportDimensions);
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    if (this.state.useDarkMode) {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.remove('light');
    }
  }

  updateViewportDimensions() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const logoFontSize = Math.min(vw / 7, 400);
    const primaryFontSize = Math.max(Math.min(logoFontSize / 5, 100), 30);
    const secondaryFontSize = Math.max(Math.min(logoFontSize / 8, 25), 18);
    const ternaryFontSize = Math.max(Math.min(logoFontSize / 12, 25), 18);
    this.setState({
      viewportWidth: vw,
      viewportHeight: vh,
      aspect: vw / vh,
      logoFontSize: logoFontSize,
      primaryFontSize: primaryFontSize,
      secondaryFontSize: secondaryFontSize,
      ternaryFontSize: ternaryFontSize
    });
  }

  setCursorState(cursorState) {
    this.setState({ cursorState: cursorState, isCursorPositionInitialised: true });
  }

  setIsCursorActive(value) {
    this.setState({ isCursorActive: value });
  }

  setCursorCol(value) {
    this.setState({ cursorCol: value });
  }

  setDarkMode(value) {
    this.setState({ useDarkMode: value });
    if (value) {
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
      document.documentElement.style.background = colors.neutral[900];
    } else {
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
      document.documentElement.style.background = colors.slate[100];
    }
  }

  render() {
    if (this.state.isMounted) {
      // Generate main content
      const content = (
        <>
          <Route exact path="/">
            <Home
              {...this.state.cursorState}
              vw={this.state.viewportWidth}
              vh={this.state.viewportHeight}
              setCursorCol={this.setCursorCol}
              toggleDarkMode={() => this.setDarkMode(!this.state.useDarkMode)}
            />
          </Route>
        </>
      );

      return (
        <>
          <CursorPosition
            vw={this.state.viewportWidth}
            vh={this.state.viewportHeight}
            setIsCursorActive={this.setIsCursorActive}
            setCursorState={this.setCursorState}
          >
            <Cursor
              col={this.state.cursorCol}
            />
          </CursorPosition>
          {this.state.isCursorPositionInitialised ? content : null}
        </>
      );
    }

    return null;
  }
}
