import React from 'react';

export default class Cursor extends React.Component {
  constructor(props) {
    super(props);
    this.width = 20;
    this.height = 20;
    this.xCorrection = -this.width / 2 - 3;
    this.yCorrection = -this.height / 2 - 2;
    this.borderWidth = 2;
    this.borderRadius = 10 + Math.max(this.width + this.borderWidth, this.height + this.borderWidth) / 2;
  }

  render() {
    if (!this.props.springPos) {
      return null;
    }

    const x = this.props.springPos.x + this.xCorrection;
    const y = this.props.springPos.y + this.yCorrection;

    return (
      <div
        id="cursor"
        style={{
          zIndex: 20,
          position: "fixed",
          width: this.width,
          height: this.height,
          borderStyle: "solid",
          borderColor: this.props.col,
          borderWidth: this.borderWidth,
          borderRadius: this.borderRadius,
          transform: "translate(" + x + "px," + y + "px)",
          pointerEvents: "none",
          transition: "borderColor 0.5s ease"
        }}
      />
    );
  }
}
